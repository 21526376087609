import { Player, Team } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { BatchTask } from "@ollie-sports/firebase";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import { getBatchTasksToUpdateDerivedForPlayerBundle } from "../../compute/playerBundle.compute";

interface Props {
  player: Player;
  updateDerivedForTeam?: boolean;
  removeGuestPlayerExpiration?: boolean;
  assignToSquad?: "a" | "b" | "c";
}

export async function player__server__updatePlayer(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let player = { ...p.player };
  if (p.removeGuestPlayerExpiration) {
    player.guestPlayerExirationMS = h._MagicDeleteValue;
  }

  const tasks: BatchTask[] = [
    await h.Player.update(
      {
        id: player.id,
        doc: player
      },
      { returnBatchTask: true }
    )
  ];

  if (p.assignToSquad) {
    const team = await h.Team.getDoc(p.player.teamId);

    if (!team?.squads?.[p.assignToSquad]) {
      throw new Error("Invalid squad for player update!");
    }

    const teamUpdate: Partial<Team> = {};
    const nonTypeSafePathSoItMatch = ["squadsPlayerMapping", p.player.id, p.assignToSquad]; //Note, this path should be idential to the type safe path above...
    _.set(teamUpdate, nonTypeSafePathSoItMatch, true);

    tasks.push(await h.Team.update({ id: p.player.teamId, doc: teamUpdate }, { returnBatchTask: true }));
  }

  await h._BatchRunner.executeBatch(tasks);

  let updateDerivedTasks: BatchTask[] = [];

  if (player.linkedPlayerBundleId) {
    updateDerivedTasks.push(
      ...(await getBatchTasksToUpdateDerivedForPlayerBundle({ playerBundleId: player.linkedPlayerBundleId }))
    );
  }

  if (p.updateDerivedForTeam) {
    // Must wait until player data is actually deleted before running the code to update the team derived code
    updateDerivedTasks = [
      ...updateDerivedTasks,
      ...(await updateDerivedForTeam({ teamId: player.teamId, executeImmediate: false }))
    ];
  }

  await h._BatchRunner.executeBatch(updateDerivedTasks);
  // SERVER_ONLY_TOGGLE
}

player__server__updatePlayer.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete

import { PlayerId } from "@ollie-sports/models";
import axios from "axios";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";

export async function player__server__updateProfileImageUris(p: {
  playerId: PlayerId;
  profileImageUri: string;
  profileImageUriSmall: string;
}) {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();

  await h.Player.update({
    id: p.playerId,
    doc: { virtualAthleteAccount: { profileImageUri: p.profileImageUri, profileImageUriSmall: p.profileImageUriSmall } }
  });
  // SERVER_ONLY_TOGGLE
}

player__server__updateProfileImageUris.auth = () => {};

// i18n certified - complete

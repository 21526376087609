import { getBatchTasksToUpdateDerivedForPlayerBundle } from "../../compute/playerBundle.compute";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";

export async function player__server__addBundleLinkToPlayer(p: { playerId: string; linkedPlayerBundleId: string }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  try {
    await h.Player.update({ id: p.playerId, doc: { linkedPlayerBundleId: p.linkedPlayerBundleId } });
    const batch = await getBatchTasksToUpdateDerivedForPlayerBundle({ playerBundleId: p.linkedPlayerBundleId });
    await h._BatchRunner.executeBatch(batch);
  } catch (e) {}
  // SERVER_ONLY_TOGGLE
}

player__server__addBundleLinkToPlayer.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete

import { AccountInfo, PlayerId } from "@ollie-sports/models";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers, sendFlexRequest } from "../../helpers";
import { maybeRefreshFlexPlayer } from "../flex/helpers/maybeRefreshFlexPlayer";

export async function player__server__updateVirtualAthleteAccount(p: { playerId: PlayerId; virtualAthleteAccount: AccountInfo }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (p.virtualAthleteAccount.profileImageUri === "") {
    p.virtualAthleteAccount.profileImageUri = h._MagicDeleteValue;
    p.virtualAthleteAccount.profileImageUriSmall = h._MagicDeleteValue;
  }

  await h.Player.update({
    id: p.playerId,
    doc: {
      virtualAthleteAccount: p.virtualAthleteAccount
    }
  });

  sendFlexRequest("flex__server__maybeRefreshFlexPlayer", {
    accountInfo: p.virtualAthleteAccount,
    playerId: p.playerId
  });

  // SERVER_ONLY_TOGGLE
}

player__server__updateVirtualAthleteAccount.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete

import { PlayerId, AccountId, PlayerBundle } from "@ollie-sports/models";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import _ from "lodash";
import { BatchTask } from "@ollie-sports/firebase";
import { getUniversalHelpers } from "../../helpers";

interface Props {
  data: { playerId: PlayerId; ignoreUpdateDerivedForTeam?: boolean };
}

export async function player__server__deleteFromTeam(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const player = await h.Player.getDoc(p.data.playerId);
  if (!player) {
    throw new Error("Unable to find player");
  }
  const tasks = await getDeleteFromTeamBatchTasks(p);
  await h._BatchRunner.executeBatch(tasks);

  // Must wait until player data is actually deleted before running the code to update the team derived code
  if (!p.data.ignoreUpdateDerivedForTeam) {
    await updateDerivedForTeam({ teamId: player.teamId, executeImmediate: true });
  }
  // SERVER_ONLY_TOGGLE
}

export async function getDeleteFromTeamBatchTasks(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const player = await h.Player.getDoc(p.data.playerId);
  if (!player) {
    throw new Error("Unable to find player");
  }
  const tasks: BatchTask[] = [];

  if (player.linkedPlayerBundleId) {
    const playerBundle = await h.PlayerBundle.getDoc(player.linkedPlayerBundleId);
    if (!playerBundle) {
      console.error("Expecting to find playerBundle but cannot find it");
    } else {
      const activeTeamIds = _(playerBundle.derived.linkedPlayers)
        .mapValues((a, playerId) => (playerId !== player.id && a.status === "active" ? a.teamId : ""))
        .values()
        .compact()
        .uniq()
        .value();

      const teams = await h.Team.getDocs(activeTeamIds);

      const activeLinkedOrgs = _(teams)
        .map(a => a?.orgId)
        .compact()
        .uniq()
        .keyBy(a => a)
        .mapValues(() => true as const)
        .value();

      const newDerived: PlayerBundle["derived"] = {
        activeLinkedOrgs,
        linkedPlayers: {
          ...playerBundle.derived.linkedPlayers,
          [player.id]: {
            ...playerBundle.derived.linkedPlayers[player.id],
            status: "inactive"
          }
        }
      };

      tasks.push(
        await h.PlayerBundle.updateShallow(
          {
            id: playerBundle.id,
            doc: { derived: newDerived }
          },
          { returnBatchTask: true }
        )
      );
    }
  }

  tasks.push(await h.Player.update({ id: p.data.playerId, doc: { deletedAtMS: Date.now() } }, { returnBatchTask: true }));
  return tasks;

  // SERVER_ONLY_TOGGLE
}

player__server__deleteFromTeam.auth = async (r: express.Request) => {
  if (r.get("x-ph-signature")) {
    throw new Error("Rejecting Posthook because we are using a different process to manage this now");
  } else {
    await validateToken(r);
  }
};

getDeleteFromTeamBatchTasks.auth = async (r: express.Request) => {
  if (r.get("x-ph-signature")) {
    //TODO: Verify signature...
  } else {
    await validateToken(r);
  }
};

// i18n certified - complete

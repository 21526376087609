import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { Player, PlayerId, AccountId, PrettyPlayer, SoccerGame, Team } from "@ollie-sports/models";
import PromiseQueue = require("promise-queue");
import { fetchPrettyPlayer, fetchPrettyPlayerList } from "../internal-utils/player-utils";
import * as express from "express";
import { validateToken } from "../internal-utils/server-auth";
import { getUniversalHelpers } from "../helpers";

export async function player__client__updateName(p: { playerId: string; firstName: string; lastName: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Player.update({ id: p.playerId, doc: { virtualAthleteAccount: { firstName: p.firstName, lastName: p.lastName } } });
}

export function player__client_getPrettyPlayerSubscription(p: { playerId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const disposeFns: any[] = [];
  const instance = createBifrostSubscription<PrettyPlayer | undefined>({ dispose: () => disposeFns.forEach(fn => fn()) });

  const promiseQueue = new PromiseQueue(1);

  const sub = h.Player.docSubscription(p.playerId).subscribe(
    pl => {
      promiseQueue.add(async () => {
        instance.nextData(await fetchPrettyPlayer(pl));
      });
    },
    e => {
      console.error(e);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

export async function player__client__fetchPlayers(p: { playerIds: string[] }): Promise<PrettyPlayer[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const rawPlayers = await h.Player.getDocs(p.playerIds);

  const players = await Promise.all(rawPlayers.map(player => fetchPrettyPlayer(player)));

  return players.filter((pl): pl is PrettyPlayer => !!pl);
}

export async function player__universal__fetchPlayersOnATeam(p: {
  teamId: string | undefined;
  filterToSquad?: "a" | "b" | "c";
}): Promise<PrettyPlayer[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (!p.teamId) {
    return [];
  }

  let team = await h.Team.getDoc(p.teamId);

  if (!team) {
    return [];
  }

  let rawPlayers = (await h.Player.query({ where: [{ teamId: ["==", p.teamId] }, { deletedAtMS: ["==", 0] }] })).docs;

  // Filter down to a squad if needed
  if (p.filterToSquad !== undefined && team.squads?.[p.filterToSquad]) {
    rawPlayers = rawPlayers.filter(pl => {
      if (!p.filterToSquad) {
        return true;
      }
      return team?.squadsPlayerMapping?.[pl.id]?.[p.filterToSquad];
    });
  }

  const players = await Promise.all(rawPlayers.map(player => fetchPrettyPlayer(player)));

  return players.filter((pl): pl is PrettyPlayer => !!pl);
}

player__universal__fetchPlayersOnATeam.auth = () => {};

export function player__client__playersOnATeamSubscription(p: { teamId: string }): BifrostSubscription<PrettyPlayer[]> {
  const disposeFns: Function[] = [];
  const instance = createBifrostSubscription<PrettyPlayer[]>({
    dispose: () => {
      try {
        disposeFns.forEach(fn => fn());
      } catch (e) {
        console.error(e);
      }
    }
  });

  const promiseQueue = new PromiseQueue(1);
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const sub = h.Player.querySubscription({ where: [{ teamId: ["==", p.teamId] }, { deletedAtMS: ["==", 0] }] }).subscribe(
    s => {
      promiseQueue.add(async () => {
        instance.nextData(await fetchPrettyPlayerList(s.docs));
      });
    },
    e => {
      console.error(e);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

export async function player__client__rawPlayersOnATeam(p: { teamId: string; squad?: "a" | "b" | "c" }): Promise<Player[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let players = (await h.Player.query({ where: [{ teamId: ["==", p.teamId] }, { deletedAtMS: ["==", 0] }] })).docs;

  if (p.squad) {
    const team = await h.Team.getDoc(p.teamId);
    if (!team) {
      throw new Error("Invalid teamId specified on rawPlayersOnATeam: " + p.teamId);
    }
    players = players.filter(pl => team.squadsPlayerMapping?.[pl.id]?.[p.squad as "a" | "b" | "c"]);
  }

  return players;
}

export async function player__client__playersOnATeam(p: { teamId: string }): Promise<PrettyPlayer[]> {
  const players = await player__client__rawPlayersOnATeam({ teamId: p.teamId });
  return await fetchPrettyPlayerList(players);
}

player__client__playersOnATeam.auth = async (r: express.Request) => {
  await validateToken(r);
};

export async function player__server__getOrgPlayer(p: {
  playerId: string;
  orgId: string;
  selfAccountId: string;
}): Promise<{ success: boolean; player?: PrettyPlayer }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const [player, selfTeams, adminOrgs] = await Promise.all([
    h.Player.getDoc(p.playerId),
    h.Team.query({
      where: [
        { deletedAtMS: ["==", 0] },
        { orgId: ["==", p.orgId] },
        { accounts: { [p.selfAccountId]: { exists: ["==", true] } } }
      ]
    }),
    h.Org.query({
      where: [{ accounts: { [p.selfAccountId]: { exists: ["==", true] } } }, { id: ["==", p.orgId] }]
    })
  ]);

  if (!player || (!selfTeams?.docs.length && !adminOrgs?.docs.length)) {
    return { success: false };
  }

  const team = await h.Team.getDoc(player.teamId);

  if (!team || team.orgId !== p.orgId) {
    return { success: false };
  }

  return { success: true, player: await fetchPrettyPlayer(player) };
  // SERVER_ONLY_TOGGLE
}

player__server__getOrgPlayer.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete

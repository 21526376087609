import { PlayerId, AccountId, TeamId } from "@ollie-sports/models";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { BatchTask } from "@ollie-sports/firebase";
import { getUniversalHelpers } from "../../helpers";
import { getDeleteFromTeamBatchTasks } from "./player__deleteFromTeam";
import _ from "lodash";

interface Props {
  playerIds: PlayerId[];
  ignoreUpdateDerivedForTeam?: boolean;
  teamId: TeamId;
}

export async function player__server__deletePlayersFromTeam(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const tasks = _.flatten(
    await Promise.all(
      p.playerIds.map(async playerId => {
        return getDeleteFromTeamBatchTasks({ data: { playerId, ignoreUpdateDerivedForTeam: true } });
      })
    )
  );
  await h._BatchRunner.executeBatch(tasks);

  // Must wait until player data is actually deleted before running the code to update the team derived code
  if (!p.ignoreUpdateDerivedForTeam) {
    await updateDerivedForTeam({ teamId: p.teamId, executeImmediate: true });
  }
  // SERVER_ONLY_TOGGLE
}

player__server__deletePlayersFromTeam.auth = async (r: express.Request) => {
  if (r.get("x-ph-signature")) {
    throw new Error("Rejecting Posthook because we are using a different process to manage this now");
  } else {
    await validateToken(r);
  }
};

// i18n certified - complete

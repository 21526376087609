import { getUniversalHelpers } from "../../helpers";
import { AccountId, PlayerId } from "@ollie-sports/models";
import { account } from "../../compute";
import shortid from "shortid";

const IMAGE_DIRECTORY = "profile_uploads/";

export async function player__client__uploadProfileImage(p: { blob: any; blobSmall: any; playerId: PlayerId }) {
  const { app, ollieFirestoreV2: h } = getUniversalHelpers();

  const player = await h.Player.getDoc(p.playerId);
  if (!player) {
    return;
  }

  // Try to delete the old profile images to save storage. If something goes wrong, just continue.
  // We don't want to stop uploading the profile image because the old one can't be deleted.
  try {
    if (player.virtualAthleteAccount.profileImageUri) {
      const deleteTask = app.storage().refFromURL(player.virtualAthleteAccount.profileImageUri).delete();
      await deleteTask;
    }

    if (player.virtualAthleteAccount.profileImageUriSmall) {
      const deleteTaskSmall = app.storage().refFromURL(player.virtualAthleteAccount.profileImageUriSmall).delete();
      await deleteTaskSmall;
    }
  } catch (e) {
    console.warn("Error deleting profile images");
  }
  const uploadTask = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + p.playerId + "/" + shortid() + ".jpg")
    .put(p.blob);

  // tslint:disable-next-line: await-promise
  await uploadTask;

  const uri = await uploadTask.snapshot.ref.getDownloadURL();

  const uploadTaskSmall = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + p.playerId + "/" + shortid() + ".jpg")
    .put(p.blobSmall);

  // tslint:disable-next-line: await-promise
  await uploadTaskSmall;

  const uriSmall = await uploadTaskSmall.snapshot.ref.getDownloadURL();

  return { uri, uriSmall };
}

// i18n certified - complete

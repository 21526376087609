import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { View } from "react-native-web";
import { Link, useParams } from "react-router-dom";
import { CenteredLoader } from "../../components/CenteredLoader";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import { getBifrost } from "../../services/bifrost.service";
import {
  ObjectKeys,
  formatMoneyCentsToDollarCentPrettyString,
  getOverallOrgInvoiceAmountDetails,
  getOverallOrgInvoiceStatus,
  isChildOrgInvoice,
  isParentOrgInvoice,
  PRETTY_OVERALL_ORG_INVOICE_STATUS,
  filterOrgPaymentInvoices
} from "@ollie-sports/core";
import { useAccounts } from "../../hooks/useAccounts";
import {
  Account,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceParent,
  OrgInvoiceTypes,
  PlayerBundle__AccountType,
  PrettyPlayer,
  Team
} from "@ollie-sports/models";
import { useTeam } from "../../hooks/useTeam";
import { PlayerCard } from "../../components/PlayerCard";
import { GuardianCard } from "../../components/GuardianCard";
import { StyledText } from "../../components/StyledText";
import { ShadowView } from "../../components/ShadowView";
import { LoadingCircle } from "../../components/LoadingCircle";
import CoolerTable from "../../components/CoolerTable";
import _ from "lodash";
import moment from "moment";
import { BackButton } from "../../components/BackButton";

export default function PlayerDetails() {
  const { teamId, playerId, orgId } = useParams<any>();

  const { data: player, isLoading: isPlayerLoading } =
    getBifrost().player__client_getPrettyPlayerSubscription.useClientSubscription(
      {
        playerId
      },
      { notifyOnMetaDataChanges: true }
    );

  const { team, isLoading: isTeamLoading } = useTeam({
    teamId
  });

  const accountIds =
    player?.derived.accountInfoSource !== "player" ? ObjectKeys(player?.derived.playerBundle.managingAccounts ?? {}) : [];
  const { accounts, isLoading: isAccountsLoading } = useAccounts({
    accountIds
  });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1, flexDirection: "column" }}>
      <BackButton />
      <View style={{ flex: 1 }}>
        {isPlayerLoading || isAccountsLoading || isTeamLoading ? (
          <CenteredLoader />
        ) : player && team ? (
          <PlayerDetailsInner player={player} accounts={accounts ?? []} team={team} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load player details" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function PlayerDetailsInner(p: { player: PrettyPlayer; accounts: Account[]; team: Team }) {
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const playerAccount = p.accounts.find(
    account =>
      p.player.derived.accountInfoSource !== "player" &&
      p.player.derived.playerBundle.managingAccounts?.[account.id]?.type === PlayerBundle__AccountType.selfAthlete
  );
  const guardianAccounts = p.accounts.filter(
    account =>
      p.player.derived.accountInfoSource !== "player" &&
      p.player.derived.playerBundle.managingAccounts?.[account.id]?.type === PlayerBundle__AccountType.guardian
  );

  const orgId = p.team.orgId;
  const playerBundle = p.player.derived.accountInfoSource !== "player" ? p.player.derived.playerBundle : undefined;

  const { data: orgInvoiceData, isLoading: isLoadingOrgInvoices } =
    getBifrost().orgInvoice__client__getOrgInvoicesForPlayerBundleId.useClient(
      {
        orgId: orgId ?? "",
        playerBundleId: playerBundle?.id ?? ""
      },
      {
        notifyOnMetaDataChanges: true,
        enabled: !!orgId && !!playerBundle
      }
    );

  const parentOrgInvoices = _.orderBy(
    (orgInvoiceData?.orgInvoices?.filter(oi => isParentOrgInvoice(oi)) ?? []) as OrgInvoiceParent[],
    a => a.createdAtMS,
    "desc"
  );

  const parentOrgInvoicesWithChildData = parentOrgInvoices.map(parentOrgInvoice => {
    const childrenOrgInvoices = (orgInvoiceData?.orgInvoices?.filter(
      o =>
        (o.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
          o.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
        o.parentOrgInvoiceId === parentOrgInvoice.id
    ) ?? []) as OrgInvoiceChild[];
    return {
      orgInvoice: parentOrgInvoice,
      ...getOverallOrgInvoiceAmountDetails({
        childrenOrgInvoices,
        parentOrgInvoice,
        orgPayments:
          filterOrgPaymentInvoices(orgInvoiceData?.orgPayments ?? []).filter(op =>
            [parentOrgInvoice.id, ...childrenOrgInvoices.map(oi => oi.id)].includes(op.invoiceId)
          ) ?? []
      })
    };
  });

  return (
    <View style={{ flex: 1, flexDirection: isMobileDevice ? "column" : "row" }}>
      <View style={{ flex: 1, marginRight: isMobileDevice ? 0 : 15 }}>
        <View>
          <StyledText style={{ fontSize: 32, fontWeight: "bold", marginBottom: 15 }}>
            {translate({ defaultMessage: "Player Details" })}
          </StyledText>
          <div className="flex-1">
            <PlayerCard player={p.player} playerAccount={playerAccount} team={p.team} />
          </div>
        </View>
        <View>
          {guardianAccounts.length ? (
            <View>
              <StyledText style={{ fontSize: 32, fontWeight: "bold", marginTop: 30, marginBottom: 15 }}>
                {translate.common.Guardians}
              </StyledText>
              <div className="flex-1">
                {guardianAccounts.map((account, index) => {
                  return (
                    <View key={account.id} style={{ marginTop: index > 0 ? 30 : 0 }}>
                      <GuardianCard account={account} team={p.team} />
                    </View>
                  );
                })}
              </div>
            </View>
          ) : null}
        </View>
      </View>
      <View style={{ flex: 1, marginLeft: isMobileDevice ? 0 : 15 }}>
        <StyledText style={{ fontSize: 32, fontWeight: "bold", marginBottom: 15 }}>{translate.common.Invoices}</StyledText>

        <ShadowView style={{ flexDirection: "row", borderRadius: 10, paddingHorizontal: 15 }}>
          {isLoadingOrgInvoices ? (
            <View style={{ alignItems: "center", flex: 1, margin: 30 }}>
              <CenteredLoader />
            </View>
          ) : parentOrgInvoices.length ? (
            <CoolerTable
              getRowHref={
                orgId
                  ? item => {
                      return `/app/org/${orgId}/invoice/${item.orgInvoice.id}/${p.player.player.id}`;
                    }
                  : undefined
              }
              style={{ flex: 1 }}
              columnDefs={[
                {
                  label: translate.common.ID,
                  getValue(item) {
                    return item.orgInvoice.id;
                  }
                },
                {
                  label: translate({ defaultMessage: "Issue Date" }),
                  getValue(item) {
                    return dateFormatters.mm_dd_yyyy(moment(item.orgInvoice.createdAtMS).toDate(), getCurrentLocale());
                  }
                },
                {
                  label: translate.common.Amount,
                  getValue(item) {
                    return formatMoneyCentsToDollarCentPrettyString(item.totalAmount);
                  }
                },
                {
                  label: translate.common.Paid,
                  getValue(item) {
                    return formatMoneyCentsToDollarCentPrettyString(item.paidAmount);
                  }
                },
                {
                  label: translate.common.Due,
                  getValue(item) {
                    return formatMoneyCentsToDollarCentPrettyString(item.remainingAmount);
                  }
                },
                {
                  label: translate.common.Status,
                  getValue(item) {
                    return PRETTY_OVERALL_ORG_INVOICE_STATUS(getCurrentLocale())[item.status];
                  }
                }
              ]}
              items={parentOrgInvoicesWithChildData}
              getItemKey={item => {
                return item.orgInvoice.id;
              }}
            />
          ) : (
            <View style={{ alignItems: "center", flex: 1, margin: 30 }}>
              <StyledText>{translate({ defaultMessage: "No Invoices" })}</StyledText>
            </View>
          )}
        </ShadowView>
      </View>
    </View>
  );
}

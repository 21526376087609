import { PlayerId, PrettyPlayer } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { fetchPrettyPlayer } from "../../internal-utils/player-utils";
import { getUniversalHelpers } from "../../helpers";

// This does not do deep subscriptions, only subscribes to the player object
export function player__client__getPrettyPlayerSubscription(p: { playerId: PlayerId }): BifrostSubscription<PrettyPlayer> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const disposeFns: Function[] = [];

  let instance = createBifrostSubscription<PrettyPlayer>({
    dispose: () => disposeFns.forEach(fn => fn())
  });

  const sub = h.Player.docSubscription(p.playerId).subscribe(
    async player => {
      let prettyPlayer = await fetchPrettyPlayer(player);
      if (prettyPlayer) {
        instance.nextData(prettyPlayer);
      } else {
        instance.nextError(new Error(`Expecting to find a prettyPlayer but unable to. PlayerId: ${p.playerId}`));
      }
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFns.push(sub.unsubscribe);

  return instance;
}

// i18n certified - complete

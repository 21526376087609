import { PlayerId, AccountId } from "@ollie-sports/models";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { BatchTask } from "@ollie-sports/firebase";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { Request } from "express";
import { schedulePosthook } from "../../internal-utils/schedulePosthook";
import { player__server__deleteFromTeam } from "./player__deleteFromTeam";

interface Props {
  playerId: PlayerId;
  expirationDateMS: number;
}

// Deprecated but can't delete from server until no one is on a client that still points to it
// Probably wait until after registration release
export async function player__server__scheduleDeletePlayerFromTeam(p: Props) {
  // SERVER_ONLY_TOGGLE
  // SERVER_ONLY_TOGGLE
}

player__server__scheduleDeletePlayerFromTeam.auth = async (req: Request) => {
  if (req.get("x-ph-signature")) {
    //TODO: Verify signature...
  } else {
    await validateToken(req);
  }
};

// i18n certified - complete

import { Player, PlayerId } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { BatchTask } from "@ollie-sports/firebase";
import { getUniversalHelpers } from "../../helpers";
import { getBatchTasksToUpdateDerivedForPlayerBundle } from "../../compute/playerBundle.compute";

interface Props {
  playerId: PlayerId;
  guestPlayerExpirationDateMS: number;
}

export async function player__server__updateGuestPlayerExpirationDateMS(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [player, ignored] = await Promise.all([
    h.Player.getDoc(p.playerId),
    h.Player.update({
      id: p.playerId,
      doc: { guestPlayerExirationMS: p.guestPlayerExpirationDateMS }
    })
  ]);

  if (player?.linkedPlayerBundleId) {
    const batch = await getBatchTasksToUpdateDerivedForPlayerBundle({ playerBundleId: player.linkedPlayerBundleId });

    await h._BatchRunner.executeBatch(batch);
  }
  // SERVER_ONLY_TOGGLE
}

player__server__updateGuestPlayerExpirationDateMS.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete

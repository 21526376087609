import { PrettyPlayer } from "@ollie-sports/models";
import express from "express";
import { getUniversalHelpers } from "../../helpers";
import { fetchPrettyPlayer } from "../../internal-utils/player-utils";
import { validateToken } from "../../internal-utils/server-auth";

export async function player__server__getPrettyPlayer(p: { playerId: string }): Promise<PrettyPlayer | undefined> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const player = await h.Player.getDoc(p.playerId);

  const prettyPlayer = await fetchPrettyPlayer(player);

  return prettyPlayer;
  // SERVER_ONLY_TOGGLE
}

player__server__getPrettyPlayer.auth = async (r: express.Request) => {
  validateToken(r);
};
// i18n certified - complete

import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { isProduction } from "../../utils";
import _ from "lodash";
import moment from "moment";
import { BatchTask } from "@ollie-sports/firebase";
import { getBatchTasksToUpdateDerivedForPlayerBundle } from "../../compute/playerBundle.compute";

export async function player__server__checkForGuestPlayersToRemove(p: {}) {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool, appOllieFirestoreV2: h } = getServerHelpers();
  const { olliePipe } = getUniversalHelpers();
  const nowMS = Date.now();
  const guestPlayersToRemove = (
    await h.Player.query({
      where: [{ guestPlayerExirationMS: ["<", moment().add(1, "minute").startOf("minute").valueOf()] }],
      limit: 500
    })
  ).docs;

  for (let i = 0; i < guestPlayersToRemove.length; i++) {
    const player = guestPlayersToRemove[i];
    await h.Player.update({
      id: player.id,
      doc: { guestPlayerExirationMS: h._MagicDeleteValue, deletedAtMS: player.deletedAtMS > 0 ? player.deletedAtMS : nowMS }
    });
    if (player.linkedPlayerBundleId) {
      const batch = await getBatchTasksToUpdateDerivedForPlayerBundle({ playerBundleId: player.linkedPlayerBundleId });

      await h._BatchRunner.executeBatch(batch);
    }
  }

  await olliePipe.emitEvent({
    type: "metric-remove-guest-players-cron",
    payload: { numPlayersRemoved: guestPlayersToRemove.length }
  });

  // SERVER_ONLY_TOGGLE
}

player__server__checkForGuestPlayersToRemove.auth = async (r: Express.Request) => {
  if (isProduction()) {
    throw new Error("Only should ever be called directly via cron jobs in production!");
  }
};
// i18n certified - complete

import { PlayerId, Player, Team__Account, PrettyPlayer, AccountId } from "@ollie-sports/models";
import { Optional } from "@ollie-sports/firebase-lift";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers, sendFlexRequest } from "../../helpers";
import { team__server__setTeamAccount } from "../team/team__updateTeamAccount.api";
import { maybeRefreshFlexPlayer } from "../flex/helpers/maybeRefreshFlexPlayer";
import { getBatchTasksToUpdateDerivedForAccount } from "../../compute/account.compute";

interface Props {
  initialPrettyPlayer: PrettyPlayer;
  updatedPlayer: Partial<Player>;
  teamAccount?: Team__Account;
  playerSelfAccountId?: AccountId;
}

export async function player__server__updatePlayerInfoForTeam(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (p.initialPrettyPlayer.derived.accountInfoSource === "player") {
    await h.Player.update({ id: p.initialPrettyPlayer.player.id, doc: p.updatedPlayer });
  } else if (p.initialPrettyPlayer.derived.accountInfoSource === "playerBundle") {
    if (p.initialPrettyPlayer.player.linkedPlayerBundleId) {
      await h.PlayerBundle.update({
        id: p.initialPrettyPlayer.player.linkedPlayerBundleId,
        doc: {
          virtualAthleteAccount: {
            firstName: p.updatedPlayer.virtualAthleteAccount?.firstName,
            lastName: p.updatedPlayer.virtualAthleteAccount?.lastName,
            profileImageUri: p.updatedPlayer.virtualAthleteAccount?.profileImageUri,
            profileImageUriSmall: p.updatedPlayer.virtualAthleteAccount?.profileImageUriSmall
          }
        }
      });
      await h.Player.update({ id: p.initialPrettyPlayer.player.id, doc: p.updatedPlayer });
    }
  } else {
    if (p.playerSelfAccountId) {
      await h.Account.update({
        id: p.playerSelfAccountId,
        doc: {
          firstName: p.updatedPlayer.virtualAthleteAccount?.firstName,
          lastName: p.updatedPlayer.virtualAthleteAccount?.lastName,
          profileImageUri: p.updatedPlayer.virtualAthleteAccount?.profileImageUri,
          profileImageUriSmall: p.updatedPlayer.virtualAthleteAccount?.profileImageUriSmall
        }
      });
      await h.Player.update({ id: p.initialPrettyPlayer.player.id, doc: p.updatedPlayer });
      await h._BatchRunner.executeBatch(await getBatchTasksToUpdateDerivedForAccount({ accountId: p.playerSelfAccountId }));
    }
  }
  if (p.teamAccount && p.playerSelfAccountId) {
    await team__server__setTeamAccount({
      accountId: p.playerSelfAccountId,
      teamAccount: p.teamAccount,
      teamId: p.initialPrettyPlayer.player.teamId
    });
  }
  sendFlexRequest("flex__server__maybeRefreshFlexPlayer", {
    accountInfo: p.updatedPlayer.virtualAthleteAccount,
    playerId: p.updatedPlayer.id
  });

  // SERVER_ONLY_TOGGLE
}
player__server__updatePlayerInfoForTeam.auth = async (r: express.Request) => {
  // Should have manage roster permission
  await validateToken(r);
};

// i18n certified - complete

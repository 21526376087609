import { PrettyPlayer } from "@ollie-sports/models";
import express from "express";
import { getUniversalHelpers } from "../../helpers";
import { fetchPrettyPlayer, fetchPrettyPlayerList } from "../../internal-utils/player-utils";
import { validateToken } from "../../internal-utils/server-auth";
import _ from "lodash";

export async function player__server__getPrettyPlayers(p: { playerIds: string[] }): Promise<PrettyPlayer[] | undefined> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const players = _.compact(await h.Player.getDocs(p.playerIds));

  const prettyPlayers = await fetchPrettyPlayerList(players);

  return prettyPlayers;
  // SERVER_ONLY_TOGGLE
}

player__server__getPrettyPlayers.auth = async (r: express.Request) => {
  validateToken(r);
};
// i18n certified - complete

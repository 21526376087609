import { PlayerId, Player } from "@ollie-sports/models";
import { Optional } from "@ollie-sports/firebase-lift";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";

interface Props {
  playerId: PlayerId;
  firstName?: string;
  lastName?: string;
  jerseyNumber: string;
}

export async function player__server__updateTeamOnlyPlayer(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let pendingUpdate: Optional<Player> = {
    jerseyNumber: p.jerseyNumber
  };

  if (p.firstName) {
    if (!pendingUpdate.virtualAthleteAccount) {
      pendingUpdate.virtualAthleteAccount = {};
    }
    pendingUpdate.virtualAthleteAccount.firstName = p.firstName;
  }
  if (p.lastName) {
    if (!pendingUpdate.virtualAthleteAccount) {
      pendingUpdate.virtualAthleteAccount = {};
    }
    pendingUpdate.virtualAthleteAccount.lastName = p.lastName;
  }

  await h.Player.update({
    id: p.playerId,
    doc: pendingUpdate
  });
  // SERVER_ONLY_TOGGLE
}
player__server__updateTeamOnlyPlayer.auth = async (r: express.Request) => {
  // Should have manage roster permission
  await validateToken(r);
};

// i18n certified - complete

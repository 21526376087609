import { PlayerId, AccountId, PlayerBundle__AccountType } from "@ollie-sports/models";
import { BatchTask } from "@ollie-sports/firebase";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";

interface Props {
  selfAccountId: AccountId;
  playerId: PlayerId;
}

// IMPORTANT!!
// This flow is meant to be a temporary. Eventually we want to create a profile separately as part of the ux
export async function player__server__addGuardian(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const player = await h.Player.getDoc(p.playerId);
  if (!player) {
    throw new Error("Unable to find player");
  }

  const tasks: BatchTask[] = [];

  let playerBundleId: string;

  if (!player.linkedPlayerBundleId) {
    // No profile exists so we create one
    const team = await h.Team.getDoc(player.teamId);

    if (!team) {
      throw new Error("Unable to find team!");
    }

    playerBundleId = h.PlayerBundle.generateId();
    tasks.push(
      await h.PlayerBundle.add(
        {
          doc: {
            id: playerBundleId,
            createdAtMS: Date.now(),
            virtualAthleteAccount: player.virtualAthleteAccount,
            managingAccounts: {
              [p.selfAccountId]: {
                exists: true,
                type: PlayerBundle__AccountType.guardian
              }
            },
            deletedAtMS: 0,
            derived: {
              activeLinkedOrgs: team.orgId ? { [team.orgId]: true } : {},
              linkedPlayers: { [player.id]: { teamId: player.teamId, status: "active" } }
            }
          }
        },
        { returnBatchTask: true }
      )
    );
    // Link the profile to the player
    tasks.push(
      await h.Player.update({ id: player.id, doc: { linkedPlayerBundleId: playerBundleId } }, { returnBatchTask: true })
    );
  } else {
    // Since the profile already exists we add them. Eventually we will require a confirmation
    tasks.push(
      await h.PlayerBundle.update(
        {
          id: player.linkedPlayerBundleId,
          doc: { managingAccounts: { [p.selfAccountId]: { exists: true, type: PlayerBundle__AccountType.guardian } } }
        },
        { returnBatchTask: true }
      )
    );
  }

  // Make sure they now have the role of guardian on the team
  tasks.push(
    await h.Team.update(
      {
        id: player.teamId,
        doc: { accounts: { [p.selfAccountId]: { exists: true, roles: { guardian: true } } } }
      },
      { returnBatchTask: true }
    )
  );

  await h._BatchRunner.executeBatch(tasks);
  await updateDerivedForTeam({ teamId: player.teamId, executeImmediate: true });

  // SERVER_ONLY_TOGGLE
}

player__server__addGuardian.auth = async (r: express.Request) => {
  await validateToken(r);
};
// i18n certified - complete

import { Player } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { getUniversalHelpers } from "../../helpers";
import { getBatchTasksToUpdateDerivedForPlayerBundle } from "../../compute/playerBundle.compute";

interface Props {
  player: Player;
}

export async function player__server__makeGuestPlayerPermanent(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.Player.update({
    id: p.player.id,
    doc: { ...p.player, ...{ guestPlayerExirationMS: h._MagicDeleteValue } }
  });

  if (p.player.linkedPlayerBundleId) {
    const batch = await getBatchTasksToUpdateDerivedForPlayerBundle({ playerBundleId: p.player.linkedPlayerBundleId });
    await h._BatchRunner.executeBatch(batch);
  }
  // SERVER_ONLY_TOGGLE
}

player__server__makeGuestPlayerPermanent.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
